import React, { useState, useEffect } from 'react';
import { Auth } from '@aws-amplify/auth';
import {
  EuiPageTemplate,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiSpacer,
  EuiButton,
  EuiProvider 
} from '@elastic/eui';
import '@elastic/eui/dist/eui_theme_light.css';
import { MainView } from './MainView';

function App() {
  const [userData, setUserData] = useState(null);

  async function logOut() {
    await Auth.signOut(); 
    window.location.reload();
  }
  
  function getUser() {
    return Auth.currentAuthenticatedUser()
      .then(user => user)
      .catch(() => {
        console.log('Not signed in');
        Auth.federatedSignIn();
      });
  }
  
  useEffect(() => {
    getUser().then(async user => {
      console.log(user);
      setUserData(user);
    });
  }, []);
  
  return (
    <div className="App">
      {userData ? (
        <EuiProvider colorMode="light">
          <MainView userData={userData} setUserData={setUserData} logOut={logOut}/>
        </EuiProvider>
      ) : (
        <EuiPageTemplate template="centeredBody">
          <EuiFlexGroup justifyContent="spaceAround">
            <EuiFlexItem grow={false}>
              <EuiText>
                <h2>Authenticating with Amazon Federate, Please Wait...</h2>
              </EuiText>
              <EuiSpacer/>
              <EuiButton 
                fill
                size='m'
                onClick={async ()=>{
                  logOut();
                }}>
              Re-Login</EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiPageTemplate>
      )}
    </div>
  );
}

export default App;
